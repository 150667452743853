.wl-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $dark;
}
.wl-header-hr {
  border-bottom: 1px solid #4c4c4c;
}
