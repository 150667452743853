// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'HM Alpha Mono';
  src: url('../fonts/HMAlphaMono.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter.ttf') format('truetype');
  font-weight: normal;
}