.faq-modal {
  .faq-section {
    max-width: 760px;
    margin: 0 auto;
  }

  header {
    margin-bottom: 70px;
  }

  header > p {
    font-size: 22px;
    color: #aeb9c6;
  }

  h2 {
    font-size: 52px;
    color: #f4f4f6;
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  details {
    cursor: pointer;
  }

  h4 {
    width: 760px;
  }

  summary {
    font-size: 20px;
    color: #f4f4f6;
    display: flex;
    justify-item: space-between;
    align-items: center;
    margin-bottom: 25px;
  }

  summary::marker {
    display: none;
    content: '';
  }

  span {
    margin-top: 4px;
    color: #f4f4f6;
  }

  details > p {
    max-width: 650px;
    color: #aeb9c6;
    font-size: 18px;
    line-height: 155%;
    letter-spacing: 0.1px;
    margin-bottom: 40px;
    padding-left: 10px;
  }

  hr {
    color: #313133;
    margin-block: 20px;
  }
  .arrow {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.tc {
  .unformatted-list {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 1rem;
    }
  }

  p,
  li,
  span {
    text-align: justify !important;
    font-size: $f5;
  }
  li {
    &::first-letter {
      text-transform: capitalize;
    }
    p {
      margin-bottom: 0;
    }
  }
  table {
    @extend .table;
    @extend .table-bordered;
    border-width: 1px;
    border-style: solid;
    width: 100%;
    td {
      vertical-align: middle !important;
    }
  }

  a {
    color: #ff5300 !important;
  }
}
