.facebook-background {
  background-color: #4460a0;
}

.twitter-background {
  background-color: #55acee;
}

.whatsapp-background {
  background-color: #67c15e;
}

.linkedin-background {
  background-color: #0a7bba;
}

.social-media-link {
  box-sizing: content-box;
  border-radius: 1.5rem;
  width: fit-content;
  padding: 0.75rem;
  cursor: pointer;
}

.social-media-icon {
  height: 24px;
  width: 24px;
}
